import Table from '@/components/Table'
import { TableCustomData } from '@/components/Table/type'
import TableRowActions from '@/components/Table/components/TableRowActions'
import { cn } from '@/lib/utils'
import { ProductStructure, ProductStructureFlat } from '@/types/Product'
import { getDateFromId } from '@/utils/date'
import { numberFormat } from '@/utils/stringFormatter'
import { createColumnHelper } from '@tanstack/react-table'
import { format } from 'date-fns'
import { Eye, ListRestart, MoreHorizontal } from 'lucide-react'
import { ReactNode, useMemo } from 'react'
import DeleteDialog from '../DeleteDialog'
import EditDialog from '../EditDialog'
import DadosMestreHeader from '@/pages/DadosMestre/components/DadosMestreHeader'
import DadosMestreHeaderActions from '@/pages/DadosMestre/components/DadosMestreHeaderActions'
import ButtonWithTooltip from '@/components/ButtonWithTooltip'
import { useTable } from '@/hooks/useTable'
import DefaultCell from '@/pages/DadosMestre/components/DefaultCell'
import { Status, statusLocale } from '@/types/Status'
import StructureStatus from '@/components/StructureStatus'
import { TypographyP } from '@/components/ui/typography'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Button } from '@/components/ui/button'
import { useNavigate } from 'react-router-dom'

interface TreeTableProps {
    data: ProductStructureFlat[]
    title: string
    isLoading: boolean
    isFetching: boolean
    onChangeTreeStructure: () => void
    onAddDialogOpen: () => void
    onDownload: () => void
    refetch: () => void
}

export interface ProductStructureTableData
    extends Partial<Omit<ProductStructure, 'COMPONENTES'>> {
    PRODUTO_FILTRO: string
    SK_TEMPO_INICIAL: number | null
    SK_TEMPO_FINAL: number | null
    QTD_COMPONENTE: string | null
    DS_REVISAO_INICIAL: string
    DS_REVISAO_FINAL: string
    DD_ORIGEM_DADO: 'MANUAL' | 'PROTHEUS'
    DS_STATUS: Status
    subRows?: ProductStructureTableData[]
}

export type ProductStructureTable = ProductStructureTableData &
    TableCustomData<ProductStructureTableData>

const columnHelper = createColumnHelper<ProductStructureTableData>()

const TreeTable = ({
    data,
    isFetching,
    isLoading,
    title,
    onChangeTreeStructure,
    onAddDialogOpen,
    onDownload,
    refetch,
}: TreeTableProps) => {
    const {
        selectedRow,
        isDeleteDialogOpen,
        isEditDialogOpen,
        onDeleteDialogClose,
        onClickDelete,
        onClickEdit,
        onEditDialogClose,
    } = useTable<ProductStructureTable>(
        import.meta.env.VITE_DADOS_MESTRE_PRODUCT_STRUCTURE_TABLE_TOKEN + 'TREE'
    )

    const navigate = useNavigate()

    const memoData: ProductStructureTableData[] = useMemo(() => {
        const treeData: ProductStructureTableData[] = []

        data.forEach((product) => {
            const productFound = treeData.find(
                (prod) =>
                    prod.SK_PRODUTO_ESTRUTURA ===
                        product.SK_PRODUTO_ESTRUTURA &&
                    prod.DD_ORIGEM_DADO === product.DD_ORIGEM_DADO
            )

            if (!productFound) {
                const parentFilter = `${product.PRODUTO_ESTRUTURA} ${product.NK_PRODUTO}`

                treeData.push({
                    ABREVIATURA_EMPRESA: product.ABREVIATURA_EMPRESA,
                    DD_ORIGEM_DADO: product.DD_ORIGEM_DADO,
                    DS_REVISAO_FINAL: '',
                    DS_REVISAO_INICIAL: '',
                    NK_PRODUTO: product.NK_PRODUTO,
                    PRODUTO_ESTRUTURA: product.PRODUTO_ESTRUTURA,
                    PRODUTO_FILTRO: parentFilter,
                    QTD_COMPONENTE: null,
                    SK_EMPRESA: product.SK_EMPRESA,
                    SK_ESTRUTURA_DE_PRODUTO: product.SK_ESTRUTURA_DE_PRODUTO,
                    SK_PRODUTO: product.SK_PRODUTO,
                    SK_PRODUTO_ESTRUTURA: product.SK_PRODUTO_ESTRUTURA,
                    SK_TEMPO_FINAL: null,
                    SK_TEMPO_INICIAL: null,
                    UM_PRODUTO_ESTRUTURA: product.UM_PRODUTO_ESTRUTURA,
                    DD_PRODUTO_PROPRIO: product.DD_PRODUTO_PROPRIO,
                    DS_STATUS: null as unknown as Status,
                    DD_ESTRUTURA_PADRAO: product.DD_ESTRUTURA_PADRAO,
                    subRows: data
                        .filter(
                            (component) =>
                                component.SK_PRODUTO_ESTRUTURA ===
                                    product.SK_PRODUTO_ESTRUTURA &&
                                component.DD_ORIGEM_DADO ===
                                    product.DD_ORIGEM_DADO
                        )
                        .map((component) => {
                            return {
                                ABREVIATURA_EMPRESA:
                                    component.ABREVIATURA_EMPRESA,
                                DD_ORIGEM_DADO: component.DD_ORIGEM_DADO,
                                DS_REVISAO_FINAL: component.DS_REVISAO_FINAL,
                                DS_REVISAO_INICIAL:
                                    component.DS_REVISAO_INICIAL,
                                NK_PRODUTO: component.NK_COMPONENTE,
                                PRODUTO_ESTRUTURA: component.PRODUTO_COMPONENTE,
                                PRODUTO_FILTRO: `${parentFilter} ${component.PRODUTO_COMPONENTE} ${component.NK_COMPONENTE}`,
                                QTD_COMPONENTE: component.QTD_COMPONENTE,
                                SK_EMPRESA: component.SK_EMPRESA,
                                SK_ESTRUTURA_DE_PRODUTO:
                                    component.SK_ESTRUTURA_DE_PRODUTO,
                                SK_PRODUTO: component.SK_PRODUTO_COMPONENTE,
                                SK_PRODUTO_ESTRUTURA:
                                    component.SK_PRODUTO_COMPONENTE,
                                SK_TEMPO_FINAL: component.SK_TEMPO_FINAL,
                                SK_TEMPO_INICIAL: component.SK_TEMPO_INICIAL,
                                DD_PRODUTO_PROPRIO:
                                    component.DD_PRODUTO_PROPRIO,
                                DS_STATUS: component.DS_STATUS,
                                DD_ESTRUTURA_PADRAO:
                                    component.DD_ESTRUTURA_PADRAO,
                                UM_PRODUTO_ESTRUTURA:
                                    component.UM_PRODUTO_COMPONENTE,
                            }
                        }),
                })
            }
        })

        return treeData
    }, [data])

    const memoColumns = useMemo(
        () => [
            columnHelper.display({
                id: 'actions',
                cell: ({ row }) => {
                    const { DD_ORIGEM_DADO } = row.original

                    return DD_ORIGEM_DADO === 'MANUAL' && row.depth === 1 ? (
                        <TableRowActions
                            onClickDelete={() => onClickDelete(row)}
                            onClickEdit={() => onClickEdit(row)}
                        />
                    ) : null
                },
                enableColumnFilter: false,
                enableSorting: false,
                enableGrouping: false,
                size: 80,
                aggregatedCell: ({ row }) => {
                    const { SK_PRODUTO_ESTRUTURA } = row.original

                    return (
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button
                                    className="bg-transparent border-none shadow-none hover:bg-transparent dark:hover:bg-transparent"
                                    size="icon"
                                    variant="ghost"
                                >
                                    <MoreHorizontal className="w-4 h-4 text-foreground" />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                                <DropdownMenuGroup>
                                    <DropdownMenuLabel>Ações</DropdownMenuLabel>
                                    <DropdownMenuItem
                                        className="gap-2"
                                        onClick={() => {
                                            if (SK_PRODUTO_ESTRUTURA) {
                                                navigate(
                                                    `status/${SK_PRODUTO_ESTRUTURA}`
                                                )
                                            }
                                        }}
                                    >
                                        <Eye size={14} />
                                        Visualizar status
                                    </DropdownMenuItem>
                                </DropdownMenuGroup>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    )
                },
            }),
            columnHelper.accessor(({ DS_STATUS }) => statusLocale[DS_STATUS], {
                id: 'DS_STATUS',
                header: 'Status',
                cell: ({ row }) => {
                    const { DS_STATUS } = row.original

                    return <StructureStatus status={DS_STATUS} />
                },
                enableGrouping: false,
                size: 100,
            }),
            columnHelper.accessor(
                ({ PRODUTO_ESTRUTURA, NK_PRODUTO }) =>
                    `${PRODUTO_ESTRUTURA} (${NK_PRODUTO})`,
                {
                    id: 'produto',
                    header: 'Produto',
                    cell: ({ row, getValue }) => {
                        return (
                            <p
                                className={cn(
                                    'flex-1 overflow-hidden text-ellipsis whitespace-nowrap',
                                    row.depth === 0 && 'text-primary-600'
                                )}
                            >
                                {getValue()}
                            </p>
                        )
                    },
                    size: 450,
                    meta: {
                        row: {
                            isGrouped: true,
                        },
                    },
                    enableGrouping: false,
                    filterFn: (row, columnId, filterValue) => {
                        const getSanitizedValue = (value: string) =>
                            value.toLowerCase().trim()

                        const productFilter = getSanitizedValue(
                            row.original.PRODUTO_FILTRO
                        )
                        const product = getSanitizedValue(
                            row.getValue(columnId) || ''
                        )

                        return (
                            productFilter.includes(
                                getSanitizedValue(filterValue as string)
                            ) ||
                            product.includes(
                                getSanitizedValue(filterValue as string)
                            )
                        )
                    },
                }
            ),
            columnHelper.accessor('ABREVIATURA_EMPRESA', {
                id: 'empresa',
                header: 'Empresa',
                size: 150,
                enableGrouping: false,
            }),
            columnHelper.accessor(
                ({ QTD_COMPONENTE }) =>
                    QTD_COMPONENTE ? numberFormat(QTD_COMPONENTE, 6) : '',
                {
                    id: 'qtde',
                    header: 'Qtde',
                    size: 100,
                    meta: {
                        cell: {
                            className: 'justify-end',
                        },
                    },
                    enableGrouping: false,
                }
            ),
            columnHelper.accessor('DS_REVISAO_INICIAL', {
                id: 'DS_REVISAO_INICIAL',
                header: 'Rev inicial',
                size: 90,
                enableGrouping: false,
            }),
            columnHelper.accessor('DS_REVISAO_FINAL', {
                id: 'DS_REVISAO_FINAL',
                header: 'Rev final',
                size: 90,
                enableGrouping: false,
            }),
            columnHelper.accessor(
                ({ SK_TEMPO_INICIAL }) =>
                    SK_TEMPO_INICIAL
                        ? format(getDateFromId(SK_TEMPO_INICIAL), 'dd/MM/yyyy')
                        : '',
                {
                    id: 'SK_TEMPO_INICIAL',
                    header: 'Dt revisão inicial',
                    size: 100,
                    enableGrouping: false,
                }
            ),
            columnHelper.accessor(
                ({ SK_TEMPO_FINAL }) =>
                    SK_TEMPO_FINAL
                        ? format(getDateFromId(SK_TEMPO_FINAL), 'dd/MM/yyyy')
                        : '',
                {
                    id: 'SK_TEMPO_FINAL',
                    header: 'Dt revisão final',
                    size: 100,
                    enableGrouping: false,
                }
            ),
            columnHelper.accessor(
                ({ DD_PRODUTO_PROPRIO }) =>
                    DD_PRODUTO_PROPRIO === 1 ? 'SIM' : 'NÃO',
                {
                    id: 'dd_produto_proprio',
                    header: 'Produto Próprio',
                    cell: ({ getValue }) => {
                        return (
                            <span
                                className={cn(
                                    'py-1 px-2 rounded-md',
                                    getValue() === 'SIM'
                                        ? 'text-green-600 bg-green-50 dark:bg-emerald-600/30 dark:text-emerald-400'
                                        : 'bg-orange-50 text-orange-400 dark:bg-orange-500/20'
                                )}
                            >
                                {getValue()}
                            </span>
                        )
                    },
                    aggregatedCell: () => null,
                    meta: {
                        cell: {
                            className: 'justify-center',
                        },
                    },
                    enableGrouping: false,
                    size: 150,
                }
            ),
            columnHelper.accessor(
                ({ DD_ESTRUTURA_PADRAO }) =>
                    DD_ESTRUTURA_PADRAO === 1 ? 'SIM' : 'NÃO',
                {
                    id: 'DD_ESTRUTURA_PADRAO',
                    header: 'Padrão',
                    cell: ({ getValue }) => {
                        return (
                            <span
                                className={cn(
                                    'py-1 px-2 rounded-md',
                                    getValue() === 'SIM'
                                        ? 'text-green-600 bg-green-50 dark:bg-emerald-600/30 dark:text-emerald-400'
                                        : 'bg-orange-50 text-orange-400 dark:bg-orange-500/20'
                                )}
                            >
                                {getValue()}
                            </span>
                        )
                    },
                    aggregatedCell: () => null,
                    meta: {
                        cell: {
                            className: 'justify-center',
                        },
                    },
                    enableGrouping: false,
                    size: 150,
                }
            ),
            columnHelper.accessor('DD_ORIGEM_DADO', {
                id: 'origem',
                header: 'Origem',
                cell: ({ getValue }) => {
                    const value = getValue()
                    let Badge: ReactNode | null = null

                    switch (value) {
                        case 'MANUAL':
                            Badge = (
                                <span className="px-2 py-1 text-xs text-green-600 rounded-lg bg-green-50 dark:bg-emerald-600/30 dark:text-emerald-400">
                                    {value}
                                </span>
                            )
                            break
                        case 'PROTHEUS':
                            Badge = (
                                <span className="px-2 py-1 text-xs rounded-lg text-cyan-600 bg-cyan-50 dark:bg-cyan-600/30 dark:text-cyan-400">
                                    {value}
                                </span>
                            )

                            break
                    }

                    return Badge
                },
                meta: {
                    cell: {
                        className: 'flex items-center justify-center',
                    },
                },
                enableGrouping: false,
                size: 100,
            }),
        ],
        []
    )

    return (
        <>
            <Table<ProductStructureTable>
                data={memoData}
                isLoading={isLoading}
                isFetching={isFetching}
                columns={memoColumns}
                tableHeader={<DadosMestreHeader title={title} />}
                persist={{
                    canPersist: true,
                    key: 'product-structure-tree-table',
                }}
                tableActions={
                    <DadosMestreHeaderActions
                        onDownload={onDownload}
                        CustomButtons={
                            <ButtonWithTooltip
                                className="flex items-center justify-center text-orange-600 rounded-md w-9 h-9 bg-orange-50 hover:bg-orange-100 dark:bg-orange-500/60 dark:hover:bg-orange-600/60"
                                tooltipContent={
                                    <TypographyP className="text-white">
                                        Dados Flat
                                    </TypographyP>
                                }
                                onClick={() => onChangeTreeStructure()}
                            >
                                <ListRestart size={18} />
                            </ButtonWithTooltip>
                        }
                        onRefech={refetch}
                        onAdd={onAddDialogOpen}
                    />
                }
                defaultColumn={{
                    cell: ({ getValue }) => (
                        <DefaultCell>{getValue() as string}</DefaultCell>
                    ),
                }}
            />
            {selectedRow && (
                <>
                    <DeleteDialog
                        row={selectedRow}
                        isOpen={isDeleteDialogOpen}
                        onClose={onDeleteDialogClose}
                    />
                    <EditDialog
                        row={selectedRow}
                        isOpen={isEditDialogOpen}
                        onClose={onEditDialogClose}
                    />
                </>
            )}
        </>
    )
}

export default TreeTable
